import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import Qm from "../../layouts/QM";
import { featuresEn } from "../../data/qm/features";


const QmEn = () => {
  const data = useStaticQuery(graphql`
    query qmDatasEn {
      image: file(relativePath: { eq: "qm/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulAvantagesGfa(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            functionalities {
              functionalities
            }
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulInstallationsGfa {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="en-CA" 
    switchLink='/gestion-de-file-dattente'
    slider={true}
    headerImage={data.image.childImageSharp} 
    title="Intelligent queue management" 
    subTitle="Attractif is the exclusive partner in Canada of a queue management solution recognized worldwide for its ease of use and robustness in terms of security." 
    anchorLink="#qm-features-section"
    anchorLinkText="Learn more">
      <Seo title="Intelligent queue management"
           description="Attractif is the exclusive partner in Canada of a queue management solution recognized worldwide for its ease of use and robustness in terms of security."
           lang="en-CA"/>
      <Qm lang="en-CA" features={featuresEn} types={data.allContentfulAvantagesGfa} carouselDatas={data.allContentfulInstallationsGfa.edges[1].node}/>
    </Layout>
  )
}

export default QmEn
